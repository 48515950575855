<template>
  <AccountApplication :id="id" isManualApplication/>
</template>
<script>
import AccountApplication from '@/components/account-application/AccountApplication.vue';

export default {
  name: 'AccountUserView',
  components: {
    AccountApplication,
  },
  props: {
    id: { type: String, required: true }
  },
}
</script>
